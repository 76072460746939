
a {
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-decoration: none;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

a:hover {
  outline: none !important;
  text-decoration: none;
  cursor: pointer;
}
a:focus, a:active, a:visited {
  outline: none !important;
  text-decoration: none;
}
a.icon i {
}
a.link {
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}

h4 {
  text-transform: uppercase !important;
  margin-bottom: 19px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.a-icon, .a-link-emphasis:after {
  -webkit-background-size: 400px 900px;
  background-size: 400px 900px;
  background: url(https://m.media-amazon.com/images/G/01/AUIClients/AmazonUIIcon-sprite_1x-003a053…._V2_.png) no-repeat;
  display: inline-block;
  vertical-align: top;
}

.a-box {
  display: block;
  border-radius: 4px;
  border: 1px #ddd solid;
  background-color: #fff;
}

.a-box .a-box-inner {
  border-radius: 4px;
  position: relative;
  padding: 14px 18px;
}

.a-alert .a-icon-alert {
  height: 27px;
  width: 30px;
  position: absolute;
  left: 18px;
  top: 11px;
}

.a-alert-success {
  border-color: #4CAF50;
}

.a-alert-success .a-alert-text {
  color: #4CAF50;
}

.a-alert-success .a-icon-alert, .a-icon-success {
  width: 27px;
  background-position: -318px -35px;
}

.a-alert-success .a-alert-container {
  background-color: #fff;
  box-shadow: 0 0 0 4px #dff7df inset;
  padding-left: 60px;
}

.a-alert-info {
  border-color: #BBD3DE;
}

.a-alert-info .a-alert-container {
  background-color: #f6f6f6;
  padding-left: 60px;
}

.a-alert-info .a-alert-container .a-alert-heading {
  color: #2b2b2b;
}

.a-alert-info .a-icon-alert, .a-icon-info {
  background-position: -350px -35px;
}

.a-alert-error {
  border-color: #f44336;
}

.a-alert-error .a-alert-text {
  color: #f44336;
}

.marginTopFive {
  margin-top: 5px;
}

.marginBottomFive {
  margin-bottom: 5px;
}

.marginTopTen {
  margin-top: 10px;
}

.marginBottomTen {
  margin-bottom: 10px;
}

.marginTopFifteen {
  margin-top: 15px;
}

.marginBottomFifteen {
  margin-bottom: 15px;
}

.marginBottomTwentyFive {
  margin-bottom: 25px;
}

.marginBottomThirtyFive {
  margin-bottom: 35px;
}

.marginBottomFifty {
  margin-bottom: 50px;
}

.refusonline-color1 {
  color: #660;
}.refusonline-color1 {
  color: #717171;
}.refusonline-color1 {
  color: #2ea3f2;
}.refusonline-color1 {
  color: #404001;
}
.gm-style-iw + div {display: none;}