.nav{
    width: 100%;
    position:fixed;
    bottom:0;
    transition-timing-function: linear;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    z-index: 99999;
    border-top: 1px solid #DDDFE1;
    background-color:white;
}
