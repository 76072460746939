:root {
  --red: #e0565b;
  --yellow: #ffffd6;
  --dark: #222222;
  --brown: #3f3f1a;
  --light: #F7F7F7;
  --gray: #efeff5;
  --white: #fff;
  --sidePadding: 3rem;
  --maxWidth: 40%;
  --containerWidth: 1200px;
}
body.dark {
  --dark: #fafafc;
  --white: #112;
  --light: #1e1e38;
  --gray: #002;
}
body.dark section.light {
  --light: #fafafc;
  --dark: #222222;
}
body.dark .mapboxgl-popup {
  --dark: #222222;
  --white: #fff;
}
body.dark .mapgl-container {
  filter: invert(100%) hue-rotate(180deg);
}

body {
  font-family: "Nunito Sans", sans-serif;
  background: var(--white);
  color: var(--dark);
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;
}

body.modalOpen {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Other styles*/

.ReactModal__Overlay{
  z-index: 10;
}

.ReactModal__Content{
  height: 50vh;
  overflow: scroll;
}

@media all and (max-width: 560px) {
  .ReactModal__Content{
    overflow-x: scroll;
  }
}

.header-cart {
  margin-left: 5px;
  font-size: 35px;
}
.cart-count-mobile {
  width: fit-content;
}
.cart-count {
  width: fit-content;
}
.nav-padding {
  padding-top: 8px;
}
.del {
    text-decoration: line-through;
}

/** Gmaps infowindow */
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style-iw.gm-style-iw-c {
 padding: 0 !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
}

.DateRangePickerInput__withBorder{
  border: none !important;
  overflow: hidden !important;
  width: 100%;
}

.searchDateRangePickerDesktop .DateRangePicker_picker {
  left: -300px !important;
}
.searchDateRangePickerDesktop .DateInput {
  width: 60px !important;
}

.searchDateRangePickerSidebar .DateRangePicker_picker {
  left: -80px !important;
}
.searchDateRangePickerDesktop .DateInput_input {
  font-size: 16px !important;
  padding: 0 !important;
  margin-left: 4px !important;
}
.searchDateRangePickerDesktop .DateRangePickerInput_arrow {
  width: 10px !important;;
}